import React from 'react'
import { Navbar } from '../../../components/Navbar'
import Footer from '../../../components/footer/Footer'
import { Box, Grid } from '@mui/material'
import { RightSideBar } from '../../../components/sidebars/RightSideBar'
import { LeftSideBar } from '../../../components/sidebars/LeftSideBar'
import { CreateDotnet6 } from './CreateDotnet6'
import { Add } from '../../adds/Add'

export const Content1 = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Add></Add>
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={3}>
                    <LeftSideBar></LeftSideBar>
                    <Grid item xs={8}>
                        <CreateDotnet6></CreateDotnet6>
                    </Grid>
                    <RightSideBar></RightSideBar>
                </Grid>
            </Box>
            <Footer></Footer>
        </div>
    )
}
