import React from 'react'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router';

export const Navbar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setUserStatus] = React.useState();
  const [isAdmin, setAdminStatus] = React.useState();

  console.log("loggedin" + isLoggedIn);
  console.log("isAdmin" + isAdmin);


  const fundraiserClick = () => {
    navigate('/setup_fundraiser');
  };

  const usersClick = () => {
    navigate('/users_fundraiser');
  };

  const clickHome = () => {
    navigate('/home');
  };

  const adminDashboard = () => {
    navigate('/ngolist');
  };

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  //signin
  const signinClick = () => {
    navigate('/signin');
  }
  //signup
  const signupClick = () => {
    navigate('/signup');
  }

  //nonProfit
  const nonProfit = () => {
    navigate('/nonprofit');
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const propertiesClose = () => {
    setAnchorEl1(null);
  };
  const open1 = Boolean(anchorEl1);
  const propertiesClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  //propertiesClose2
  const [anchorEl2, setanchorEl3] = React.useState(null);

  const propertiesClose2 = () => {
    setanchorEl3(null);
  };
  const open2 = Boolean(anchorEl2);
  const propertiesClick2 = (event) => {
    setanchorEl3(event.currentTarget);
  };

  //logout
  const logOutClick = async () => {
    setUserStatus(false);
    setAdminStatus(false);
    localStorage.setItem("userToken", null);
    localStorage.setItem("isAdmin", false);
    localStorage.setItem("isLoggedIn", false);
    navigate('/home');
  }

  React.useEffect(() => {
    setAdminStatus(localStorage.getItem("isAdmin") === 'true' ? true : false);
    setUserStatus(localStorage.getItem("isLoggedIn") === 'true' ? true : false);
  }, []);

  return (
    <Box sx={{
      flexGrow: 1
    }}>
      <AppBar position="sticky" sx={{
        boxSizing: "border-box",
        padding: "0.5rem 1rem",
        display: "flex",
        alignItems: "center",
        flexFlow: "row nowrap",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        transition: "box-shadow 0.5s ease 0s",
        position: "sticky",
        top: "0px",
        zIndex: 1020,
        marginTop: "0px",
        backgroundColor: "rgb(41 36 75)",
        marginRight: "0px",
        marginLeft: "0px",
        paddingRight: "0px",
        paddingLeft: "0px",
        paddingTop: "1rem",
        paddingBottom: "1rem"
      }}>
        <div
          className="container-fluid"
          style={{
            boxSizing: "border-box",
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            maxWidth: "1140px",
            paddingLeft: "max(1.5rem, env(safe-area-inset-left))",
            paddingRight: "max(1.5rem, env(safe-area-inset-right))",
          }}
        >
          {/* logo */}
          <a
            className="navbar-brand mr-0 mr-sm-3"
            onClick={clickHome}
            style={{
              boxSizing: "border-box",
              textDecoration: "none",
              backgroundColor: "transparent",
              display: "inline-block",
              paddingTop: "0.3125rem",
              paddingBottom: "0.3125rem",
              fontSize: "1.25rem",
              lineHeight: "inherit",
              whiteSpace: "nowrap",
              color: "rgb(127, 94, 223)",
              marginRight: "1rem",
            }}
          >
            {/* <img
              alt="Pledge"
              src={'https://cdn.logo.com/hotlink-ok/logo-social.png'}
              onClick={clickHome}
              style={{
                boxSizing: "border-box",
                borderStyle: "none",
                verticalAlign: "middle",
                height: "2rem",
                marginTop: "0.25rem",
              }}
            /> */}
            <b
              onClick={clickHome}
            >CSHARPME</b>
          </a>

          {/* sign */}
          {isLoggedIn === false ?
            <ul
              className="navbar-nav order-lg-1 flex-row"
              style={{
                boxSizing: "border-box",
                marginTop: "0px",
                order: 1,
                listStyle: "none",
                display: "flex",
                paddingLeft: "0px",
                marginBottom: "0px",
                flexDirection: "row",
              }}
            >
              <li
                className="nav-item d-none d-sm-block ml-1 ml-sm-2"
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  marginLeft: "0.5rem",
                }}
              >
                <a
                  className="btn text-primary"
                  href=""
                  style={{
                    boxSizing: "border-box",
                    textDecoration: "none",
                    border: "1px solid transparent",
                    padding: "0.375rem 0.75rem",
                    borderRadius: "0.25rem",
                    transition:
                      "color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
                    display: "inline-block",
                    fontWeight: 600,
                    textAlign: "center",
                    verticalAlign: "middle",
                    userSelect: "none",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    cursor: "pointer",
                    color: "rgb(127, 94, 223)",
                  }}
                  onClick={signinClick}
                >
                  Sign In
                </a>
              </li>
              <li
                className="nav-item ml-1 ml-sm-2"
                style={{ boxSizing: "border-box", marginLeft: "0.5rem" }}
              >
                <a
                  className="btn btn-primary"
                  href=""
                  style={{
                    boxSizing: "border-box",
                    textDecoration: "none",
                    border: "1px solid transparent",
                    padding: "0.375rem 0.75rem",
                    borderRadius: "0.25rem",
                    transition:
                      "color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
                    display: "inline-block",
                    fontWeight: 600,
                    textAlign: "center",
                    verticalAlign: "middle",
                    userSelect: "none",
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    borderColor: "rgb(127, 94, 223)",
                    color: "rgb(255, 255, 255)",
                    backgroundColor: "rgb(127, 94, 223)",
                    cursor: "pointer",
                  }}
                  onClick={signupClick}
                >
                  Sign Up
                </a>
              </li>
            </ul>
            : ""}

          {/* logout */}

          {isLoggedIn === true ?
            <ul
              className="navbar-nav order-lg-1 flex-row"
              style={{
                boxSizing: "border-box",
                marginTop: "0px",
                order: 1,
                listStyle: "none",
                display: "flex",
                paddingLeft: "0px",
                marginBottom: "0px",
                flexDirection: "row",
              }}
            >
              <li
                className="nav-item d-none d-sm-block ml-1 ml-sm-2"
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  marginLeft: "0.5rem",
                }}
              >
                <a
                  className="btn btn-primary"
                  href=""
                  style={{
                    boxSizing: "border-box",
                    textDecoration: "none",
                    border: "1px solid transparent",
                    padding: "0.375rem 0.75rem",
                    borderRadius: "0.25rem",
                    transition:
                      "color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
                    display: "inline-block",
                    fontWeight: 600,
                    textAlign: "center",
                    verticalAlign: "middle",
                    userSelect: "none",
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    borderColor: "rgb(127, 94, 223)",
                    color: "rgb(255, 255, 255)",
                    backgroundColor: "rgb(127, 94, 223)",
                    cursor: "pointer",
                  }}
                  onClick={logOutClick}
                >
                  Logout
                </a>
              </li>
            </ul>
            : ""}
        </div>
      </AppBar>
    </Box>
  )
}
