import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { LeftSideBar } from '../../components/sidebars/LeftSideBar';
import { RightSideBar } from '../../components/sidebars/RightSideBar';
import { Box, Divider, Grid } from '@mui/material';
import { Navbar } from '../../components/Navbar';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router';
import { Add } from '../adds/Add';


export const ContentList = () => {
    const navigate = useNavigate();
    const creatingDonet6 = () => {
        navigate('/creating-donet6');
    };

    return (
        <div>
            <Navbar></Navbar>
            <Box sx={{
                flexGrow: 1,
                height: '1000px',
                backgroundImage: `url("https://th.bing.com/th/id/R.e147466f2bf919d3a0b193dc3d1c526e?rik=ibaCisCSVysPiQ&riu=http%3a%2f%2fwallup.net%2fwp-content%2fuploads%2f2016%2f01%2f23167-digital_art-space-JoeyJazz-space_art-nebula.jpg&ehk=tA5uPEC2MAY3uw%2bB0EJlMOVwCkr4KPSv%2bTO%2f3Ar3t6g%3d&risl=&pid=ImgRaw&r=0")`
            }}>
                <Add></Add>
                <Grid container spacing={3}>
                    <LeftSideBar></LeftSideBar>
                    <Grid item xs={8}>
                        <br></br>
                        <List sx={{ width: '100%', background: 'rgba(1, 1, 1, 0.5)', color: 'white' }}>
                            <ListItem onClick={creatingDonet6}>
                                <ListItemAvatar>
                                    <Avatar>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Create .NET 6 Web API Project" />
                            </ListItem>
                            <Divider></Divider>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Tools for .NET 6" />
                            </ListItem>
                        </List>
                    </Grid>
                    <RightSideBar></RightSideBar>
                </Grid>
                <br></br>
            </Box>
            <Footer></Footer>
        </div>
    )
}
