import React from 'react'
import Create_New_Project from '../content1/images/createnewproject.png'
import Configure from '../content1/images/configure.png'
import Additional_Information from '../content1/images/Additional_Information.png'
import Project_Creation_Loading from '../content1/images/Project_Creation_Loading.png'
import Created_Project_Structure from '../content1/images/Created_Project_Structure.png'
import Directory_Structure from '../content1/images/Directory_Structure.png'
import Run_Application from '../content1/images/Run_Application.png'
import Final_Result from '../content1/images/Final_Result.png'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { alignProperty } from '@mui/material/styles/cssUtils'


export const CreateDotnet6 = () => {
    return (
        <Box
            sx={{
                backgroundColor: 'lavender',
            }}
        >
            <br></br>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><span style={{ fontSize: '27px', lineHeight: '107%', fontFamily: '"Segoe UI", sans-serif', color: 'rgb(0, 0, 0)' }}>
                Create .NET 6 Web API Project</span></strong>
            </p>
            <p style={{ marginTop: '1cm', marginRight: '1cm', marginBottom: '1pt', marginLeft: '1cm', lineHeight: '107%', fontSize: '18px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}>
            .NET 6 is a versatile and powerful framework designed for building modern applications, including web APIs, web apps, desktop applications, and cross-platform mobile apps. It provides a unified platform, performance enhancements, and a comprehensive set of libraries, making it a popular choice for developers.
            <br></br>
            <br></br>
            This guide will take you through the steps to create a new .NET 6 project, focusing on a web API. By the end of this guide, you'll have a functioning .NET 6 web API project, ready for further development.

            </p>
            <br></br>
            <Divider>
                <Chip sx={{ background: 'rgb(127, 94, 223)', color: 'white' }} label="Step 1" />
            </Divider>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '27px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}>
                <strong><span style={{ fontSize: '20px', lineHeight: '107%', color: 'black' }}></span><span style={{ fontSize: '25px', lineHeight: '107%', color: 'rgb(85, 57, 130)' }}><u>Create New Project</u></span></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '36.0pt', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>
                <img width='578' src={Create_New_Project} />
            </span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 1: Create New Project&nbsp;</span></em></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></em></strong></p>
            <ul style={{ listStyleType: 'disc' }}>
                <ul>
                    <ul>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>Choose ASP .NET Core Web API project template.</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14.0pt', color: 'black' }}>Click <strong>Next</strong></span></li>
                    </ul>
                </ul>
            </ul>

            <br></br>
            <Divider>
                <Chip sx={{ background: 'rgb(127, 94, 223)', color: 'white' }} label="Step 2" />
            </Divider>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>

            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}><strong><span style={{ fontSize: '20px', lineHeight: '107%', color: 'black' }}> </span><span style={{ fontSize: '20px', lineHeight: '107%', color: 'rgb(85, 57, 130)' }}><u>Configure Project</u></span></strong></p>

            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>
                <img width='547' src={Configure} />
            </span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 2: Configure</span></em></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <ul style={{ listStyleType: 'disc', text: 'left' }}>
                <ul>
                    <ul>

                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>Enter Project Name.</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>Desired Location</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><strong><span style={{ lineHeight: '', fontSize: '14.0pt', color: 'black' }}>Check</span></strong><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>&nbsp;Place solution and project in same directiory&nbsp;</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14.0pt', color: 'black' }}>Click <strong>Next</strong></span></li>

                    </ul>
                </ul>
            </ul>
            <br>
            </br>
            <Divider>
                <Chip sx={{ background: 'rgb(127, 94, 223)', color: 'white' }} label="Step 3" />
            </Divider>

            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>

            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}><strong><span style={{ fontSize: '20px', lineHeight: '107%', color: 'black' }}></span><span style={{ fontSize: '20px', lineHeight: '107%', color: 'rgb(85, 57, 130)' }}><u></u></span></strong><span style={{ color: 'rgb(85, 57, 130)' }}><strong><span style={{ fontSize: '20px', lineHeight: '107%' }}><u>Additional Information</u></span></strong></span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '36.0pt', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>
                <img width='602' src={Additional_Information} />
            </span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 3: Additional Information</span></em></strong></p>
            <br></br>
            <ul style={{ listStyleType: 'disc' }}>
                <ul>
                    <ul>
                        <li style={{ textAlign: 'left', fontFamily: 'Calibri, sans-serif' }}><strong><span style={{ fontSize: '14pt', color: 'black' }}>Check</span></strong><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>&nbsp;Enable open API for Swagger Documentation.</span></li>
                        <li style={{ textAlign: 'left', fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14.0pt', color: 'black' }}>Click <strong>Create.</strong></span></li>
                    </ul>
                </ul>
            </ul>

            <p style={{ margin: '0cm 0cm 8pt 120px', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'left' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>
                <img width='602' src={Project_Creation_Loading} />
            </span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 4: Project Creation Loading</span></em></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}>
                <img width='602' src={Created_Project_Structure} />
            </p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 5: Created Project Structure</span></em></strong></p>
            <br></br>
            <br></br>

            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}>
                <img width='602' src={Directory_Structure} /></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 6: Directory Structure</span></em></strong></p>
            <br></br>
            <br></br>
            <Divider>
                <Chip sx={{ background: 'rgb(127, 94, 223)', color: 'white' }} label="Step 4" />
            </Divider>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}><strong><span style={{ fontSize: '20px', lineHeight: '107%', color: 'black' }}></span><span style={{ fontSize: '20px', lineHeight: '107%', color: 'rgb(85, 57, 130)' }}><u>Run the Application on the Browser</u></span></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}>
                <br />
            </p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}>
                <img width='602' src={Run_Application} />
            </p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 7: Run Application</span></em></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <ul style={{ listStyleType: 'disc' }}>
                <ul>
                    <ul>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>Choose IIS Express</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14pt', color: 'black' }}>Choose your Desired Browser&nbsp;</span></li>
                        <li style={{ fontFamily: 'Calibri, sans-serif' }}><span style={{ lineHeight: '107%', fontSize: '14.0pt', color: 'black' }}>Click <strong>RUN</strong></span></li>
                    </ul>
                </ul>
            </ul>

            <p style={{ margin: '0cm 0cm 8pt 120px', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>
            <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri, sans-serif', textAlign: 'center' }}>
                <img width='602' src={Final_Result} />
            </p>

            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><em><span style={{ fontSize: '19px', lineHeight: '107%', color: 'rgb(41, 105, 176)' }}>Screenshot 8: Final Result</span></em></strong></p>
            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '19px', lineHeight: '107%', color: 'black' }}>&nbsp;</span></p>

            <br>
            </br>
            <Divider>
                <Chip sx={{ background: 'rgb(127, 94, 223)', color: 'white' }} label="END" />
            </Divider>
            <br></br>
        </Box>
    )
}
