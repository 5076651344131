import React from 'react'
import { useNavigate } from 'react-router';

export const Section1 = () => {
    const navigate = useNavigate();

    const clickHome = () => {
        navigate('/contents');
      };
    
    return (
        <div style={{
            backgroundImage: `url("https://th.bing.com/th/id/R.e147466f2bf919d3a0b193dc3d1c526e?rik=ibaCisCSVysPiQ&riu=http%3a%2f%2fwallup.net%2fwp-content%2fuploads%2f2016%2f01%2f23167-digital_art-space-JoeyJazz-space_art-nebula.jpg&ehk=tA5uPEC2MAY3uw%2bB0EJlMOVwCkr4KPSv%2bTO%2f3Ar3t6g%3d&risl=&pid=ImgRaw&r=0")`
        }}>
            <div
                className="px-3 home-campaign-hero"
                style={{
                    boxSizing: "border-box",
                    paddingRight: "var(--base-size-16, 16px) ",
                    paddingLeft: "var(--base-size-16, 16px) ",
                    alignContent:"center",
                    color: "#fff",
                }}
            >
                <div
                    className="position-relative pt-3 pt-md-8 pt-lg-12 container-xl js-build-in-trigger build-in-animate"
                    style={{
                        boxSizing: "border-box",
                        maxWidth: "1280px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        position: "relative",
                        paddingTop: "var(--base-size-128, 128px) ",
                    }}
                >

                    <div
                        className="d-flex"
                        style={{ boxSizing: "border-box", display: "flex" }}
                    >
                        <div
                            className="col-1 d-flex flex-column events-none position-relative"
                            style={{
                                boxSizing: "border-box",
                                width: "8.33333%",
                                pointerEvents: "none",
                                flexDirection: "column",
                                position: "relative",
                                display: "flex",
                            }}
                        >

                            <div
                                className="mx-auto my-3 home-campaign-glowing-icon"
                                style={{
                                    boxSizing: "border-box",
                                    transform: "translateZ(0px)",
                                    marginTop: "var(--base-size-16, 16px) ",
                                    marginBottom: "var(--base-size-16, 16px) ",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                }}
                            >

                            </div>

                        </div>
                        <div
                            className="col-11 text-left pt-12 mt-12 pl-2 pl-sm-0"
                            style={{
                                boxSizing: "border-box",
                                width: "91.6667%",
                                paddingLeft: "0px",
                                textAlign: "left",
                            }}
                        >
                            <div
                                className="position-relative top-md-8 z-1"
                                style={{
                                    boxSizing: "border-box",
                                    position: "relative",
                                    top: "64px",
                                    zIndex: 1,
                                }}
                            >
                                <a
                                    className="eyebrow-banner d-inline-block no-underline color-fg-on-emphasis f5-mktg mb-4 border position-relative z-1 arrow-target-mktg text-left"
                                    href=" "
                                    style={{
                                        boxSizing: "border-box",
                                        fontFamily:
                                            '"Mona Sans", "Mona Sans Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        fontWeight: "var(--base-text-weight-normal, 400)",
                                        fontFeatureSettings: "unset",
                                        background:
                                            "linear-gradient(90deg, rgba(1, 1, 1, 0.6) 0%, rgba(1, 1, 1, 0.463) 59.76%, rgba(1, 1, 1, 0) 100%)",
                                        borderRadius: "100px",
                                        maxWidth: "573px",
                                        backgroundColor: "initial",
                                        padding: "14px 20px",
                                        border:
                                            "var(--primer-borderWidth-thin, 1px) solid #30363d ",
                                        color: "#ffffff ",
                                        position: "relative",
                                        marginBottom: "var(--base-size-24, 24px) ",
                                        textAlign: "left",
                                        textDecoration: "none",
                                        display: "inline-block",
                                        lineHeight: "20px",
                                        zIndex: 1,
                                        borderColor: "rgb(66, 75, 91)",
                                        fontSize: "16px",
                                    }}
                                >
                                    <div
                                        className="d-flex flex-items-center"
                                        style={{
                                            boxSizing: "border-box",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                        onClick={clickHome}
                                    >
                                        <div
                                            className="pr-3 mr-md-1 pl-md-1"
                                            style={{
                                                boxSizing: "border-box",
                                                marginRight: "var(--base-size-4, 4px) ",
                                                paddingRight: "var(--base-size-16, 16px) ",
                                                paddingLeft: "var(--base-size-4, 4px) ",
                                            }}
                                        >
                                            <div
                                                className="text-semibold lh-condensed"
                                                style={{
                                                    boxSizing: "border-box",
                                                    lineHeight: 1.25,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Go To Content
                                            </div>
                                            <div
                                                className="color-fg-subtle lh-condensed"
                                                style={{
                                                    boxSizing: "border-box",
                                                    color: "#6e7681 ",
                                                    lineHeight: 1.25,
                                                }}
                                            >
                                                Start csahrping
                                            </div>
                                        </div>
                                        <div
                                            className="ml-auto"
                                            style={{ boxSizing: "border-box", marginLeft: "auto" }}
                                        >
                                            <svg
                                                className="octicon arrow-symbol-mktg"
                                                height="16"
                                                width="16"
                                                fill="none"
                                                viewBox="0 0 16 16"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    verticalAlign: "text-bottom",
                                                    fill: "currentcolor",
                                                    transition: "transform 0.2s ease 0s",
                                                    transform: "translateX(0px)",
                                                    width: "1em",
                                                    height: "1em",
                                                    overflow: "visible",
                                                }}
                                            >
                                                <path
                                                    d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"
                                                    fill="currentColor"
                                                    style={{ boxSizing: "border-box" }}
                                                />
                                                <path
                                                    className="octicon-chevrow-stem"
                                                    d="M1.75 8H11"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeWidth="1.5"
                                                    style={{
                                                        boxSizing: "border-box",
                                                        transition: "stroke-dashoffset 0.2s ease 0s",
                                                        strokeDasharray: "10",
                                                        strokeDashoffset: 10,
                                                    }}
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <h1
                                className="h0-mktg mt-sm-6 mt-md-11 mt-lg-9 mb-2 mb-sm-4 position-relative z-2"
                                style={{
                                    boxSizing: "border-box",
                                    margin: "0.67em 0px",
                                    fontFamily:
                                        '"Mona Sans", "Mona Sans Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                    fontFeatureSettings: '"ss02", "ss01"',
                                    position: "relative",
                                    marginBottom: "var(--base-size-24, 24px) ",
                                    marginTop: "var(--base-size-80, 80px) ",
                                    letterSpacing: "-0.01em",
                                    fontSize: "96px",
                                    lineHeight: "100px",
                                    zIndex: 2,
                                    fontWeight: 600,
                                }}
                            >
                                {"Let’s Code Together"}
                            </h1>
                            <p
                                className="f1-mktg col-11 col-lg-10 text-normal color-fg-muted mr-lg-n4 mb-3 mb-md-4 mb-md-7 position-relative z-1"
                                style={{
                                    boxSizing: "border-box",
                                    marginTop: "0px",
                                    width: "83.3333%",
                                    fontFamily:
                                        '"Mona Sans", "Mona Sans Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                    fontFeatureSettings: "unset",
                                    color: "#8b949e ",
                                    position: "relative",
                                    marginBottom: "var(--base-size-48, 48px) ",
                                    marginRight: "calc(-1*var(--base-size-24, 24px)) ",
                                    fontWeight: "var(--base-text-weight-normal, 400) ",
                                    letterSpacing: "-0.01em",
                                    fontSize: "32px",
                                    lineHeight: "44px",
                                    zIndex: 1,
                                }}
                            >
                                {
                                    "Harnessed for productivity. Designed for collaboration. Celebrated for built-in security. Welcome to the platform developers love."
                                }
                            </p>
                            <div
                                className="d-flex flex-column flex-md-row"
                                style={{
                                    boxSizing: "border-box",
                                    flexDirection: "row",
                                    display: "flex",
                                }}
                            >
                                <form
                                    className="mx-0 col-12 col-md-5 js-signup-form position-relative z-2"
                                    autoComplete="off"
                                    acceptCharset="UTF-8"
                                    action="/signup"
                                    method="get"
                                    style={{
                                        boxSizing: "border-box",
                                        width: "41.6667%",
                                        position: "relative",
                                        marginRight: "0px",
                                        marginLeft: "0px",
                                        zIndex: 2,
                                    }}
                                >
                                    {" "}
                                    <div
                                        className="d-flex flex-column flex-md-row flex-items-center"
                                        style={{
                                            boxSizing: "border-box",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            display: "flex",
                                        }}
                                    >
                                        <dl
                                            className="col-12 my-0 mb-3 mb-md-0 flex-auto"
                                            style={{
                                                boxSizing: "border-box",
                                                width: "100%",
                                                flex: "1 1 auto",
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <dt style={{ boxSizing: "border-box" }}>
                                                <label
                                                    className="sr-only"
                                                    htmlFor="user_email"
                                                    style={{
                                                        boxSizing: "border-box",
                                                        fontWeight: "var(--base-text-weight-semibold, 600)",
                                                        margin: "-1px",
                                                        padding: "0px",
                                                        overflow: "hidden",
                                                        border: "0px",
                                                        position: "absolute",
                                                        width: "1px",
                                                        height: "1px",
                                                        clip: "rect(0px, 0px, 0px, 0px)",
                                                        overflowWrap: "normal",
                                                    }}
                                                >
                                                    Email address
                                                </label>
                                            </dt>
                                            <dd
                                                style={{ boxSizing: "border-box", marginLeft: "0px" }}
                                            >
                                                <input
                                                    id="user_email"
                                                    className="form-control f4-mktg width-full rounded-md-right-0"
                                                    name="user_email"
                                                    type="email"
                                                    autoComplete="off"
                                                    placeholder="Email address"
                                                    style={{
                                                        boxSizing: "border-box",
                                                        font: "inherit",
                                                        margin: "0px",
                                                        overflow: "visible",
                                                        padding: "5px 12px",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "right 8px center",
                                                        border: "1px solid #d0d7de",
                                                        borderRadius: "6px",
                                                        transition:
                                                            "color 80ms cubic-bezier(0.33, 1, 0.68, 1) 0s, background-color, box-shadow, border-color",
                                                        color: "#24292f",
                                                        verticalAlign: "middle",
                                                        backgroundColor: "#ffffff",
                                                        boxShadow: "inset 0 1px 0 rgba(208,215,222,0.2)",
                                                        fontFamily:
                                                            '"Mona Sans", "Mona Sans Fallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                                        fontWeight: "var(--base-text-weight-normal, 400)",
                                                        fontFeatureSettings: "unset",
                                                        colorScheme: "light",
                                                        height: "3rem",
                                                        borderTopRightRadius: "0px",
                                                        borderBottomRightRadius: "0px",
                                                        width: "100%",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                    }}
                                                />
                                            </dd>
                                        </dl>
                                        <input
                                            name="source"
                                            type="hidden"
                                            defaultValue="form-home-signup"
                                            style={{
                                                boxSizing: "border-box",
                                                font: "inherit",
                                                margin: "0px",
                                                overflow: "visible",
                                                fontFamily: "inherit",
                                                fontSize: "inherit",
                                                lineHeight: "inherit",
                                            }}
                                        />
                                        <button
                                            className="btn-mktg width-full width-md-auto mb-3 mb-md-0 rounded-md-left-0 home-campaign-signup-button btn-signup-mktg"
                                            type="submit"
                                            style={{
                                                boxSizing: "border-box",
                                                font: "inherit",
                                                margin: "0px",
                                                overflow: "visible",
                                                textTransform: "none",
                                                fontFamily: "inherit",
                                                cursor: "pointer",
                                                padding: "0.9rem 1.5rem 1.1rem",
                                                border: "0px",
                                                borderRadius: "0.375rem",
                                                transition:
                                                    "box-shadow 0.2s ease 0s, outline 0.2s ease 0s",
                                                position: "relative",
                                                zIndex: 1,
                                                display: "inline-block",
                                                fontSize: "1rem",
                                                fontWeight: 700,
                                                lineHeight: 1,
                                                textAlign: "center",
                                                whiteSpace: "nowrap",
                                                verticalAlign: "middle",
                                                userSelect: "none",
                                                color: "rgb(255, 255, 255)",
                                                paddingTop: "1rem",
                                                paddingBottom: "1rem",
                                                borderBottomLeftRadius: "0px",
                                                borderTopLeftRadius: "0px",
                                                width: "auto",
                                                marginBottom: "0px",
                                                appearance: "none",
                                                background:
                                                    "linear-gradient(rgba(183, 52, 179, 0.15) 0%, rgba(164, 46, 156, 0) 100%), rgb(110, 64, 201)",
                                            }}
                                        >
                                            {"Sign up for GitHub"}
                                        </button>
                                    </div>
                                </form>
                                <div
                                    className="border-top border-md-left mx-md-3 mb-3 mb-md-0"
                                    style={{
                                        boxSizing: "border-box",
                                        borderTop:
                                            "var(--primer-borderWidth-thin, 1px) solid #30363d ",
                                        borderLeft:
                                            "var(--primer-borderWidth-thin, 1px) solid #30363d ",
                                        marginBottom: "0px",
                                        marginRight: "var(--base-size-16, 16px) ",
                                        marginLeft: "var(--base-size-16, 16px) ",
                                    }}
                                />
                            </div>
                            <div
                                className="my-10 my-md-12 build-in-animate col-12"
                                style={{
                                    boxSizing: "border-box",
                                    width: "100%",
                                    marginTop: "var(--base-size-128, 128px) ",
                                    marginBottom: "var(--base-size-128, 128px) ",
                                }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
