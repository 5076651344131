import React from 'react'
import Footer from '../../components/footer/Footer'
import { Navbar } from '../../components/Navbar'
import { Add } from '../adds/Add'
import { Section1 } from './Section1'

export const HomePage = () => {
  return (
    <div>
        <Navbar></Navbar>
        <Section1></Section1>
        <Footer></Footer>
    </div>
  )
}
