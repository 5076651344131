import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { HomePage } from './pages/homepage/HomePage';
import { Content1 } from './pages/content/content1/Content1';
import { ContentList } from './pages/contentlist/ContentList';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="creating-donet6" element={<Content1 />} />
      <Route path="contents" element={<ContentList />} />
      <Route path="*" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
