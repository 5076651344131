import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="white" textAlign="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];


export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '50vh',
        backgroundColor: "rgb(41 36 75)"
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
        <Container
          maxWidth="lg"
          component="footer"
          sx={{
            p: 2
          }}
        >
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title} color={'white'}>
                <ul>
                <Typography variant="h6" color={'white'} sx={{textAlign:"left",paddingLeft:4}} gutterBottom>
                  {footer.title}
                </Typography>
                  {footer.description.map((item) => (
                    <ul key={item} >
                      <Typography href="#" variant="subtitle1" color={'white'}  sx={{textAlign:"left"}}>
                        {item}
                      </Typography>
                    </ul>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Container>
        <hr></hr>
        <Container sx={{ p: 2 }}>
          {/* <Typography variant='h6' color={'white'}>Safe Harbor Statement</Typography> */}
          {/* <Typography variant="body1" component="h2" gutterBottom color={'white'}>
            {'Certain statements contained in this document constitute forward-looking statements. Such forward-looking statements, including our intended actions and performance objectives, projections, targets, estimates, or forecasts involve known and unknown risks, uncertainties and other important factors, including our ability to successfully develop and operate the Vairt Marketplace, our business model and the services we intend to offer. These factors are based on expectations and assumptions that may change and are subject to regulatory uncertainty, changes in our legal environment and the blockchain and real estate industries generally, which could cause our actual results, performance or achievements to differ materially from any future results, performance or achievements expressed or implied by such forward-looking statements. All amounts, numbers, ranges, and values presented in this document are subject to material change and should be taken as preliminary and indicative only. Any such amounts, numbers, ranges, and values are based upon preliminary estimates, remain subject to ongoing analysis and may change materially. All forward-looking statements in this document speak only as of the date hereof. We expressly disclaim any obligation to update any forward-looking statement to reflect any change in our expectation or any change in events, conditions, assumptions or circumstances.'}
          </Typography> */}
        </Container>
      </Container>
      <Copyright />
    </Box>
  );
}